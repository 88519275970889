import { styled } from '@mui/material'
import LoginButton from '@src/components/account/login/LoginButton'
import SharedPrimaryNavBar from '@src/components/navigationBar/primary/SharedPrimaryNavBar'
import { ROUTE } from '@src/constants/route'
import queryString from 'query-string'
import { FC } from 'react'

const Bar = styled(SharedPrimaryNavBar)`
  .Custom-Container {
    .Custom-Toolbar {
      justify-content: space-between;
    }
  }
`

const HomePrimaryNavBar: FC<Props> = () => {
  const url = queryString.stringifyUrl({
    url: ROUTE.BATTLE_DEMO_GAME,
    query: {
      promptLogin: true
    }
  })

  return (
    <Bar>
      <LoginButton url={url} />
    </Bar>
  )
}

type Props = {}

export default HomePrimaryNavBar
