import { useAccountContext } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import DownloadClientPage from '@pages/battle-demo/game'
import HomePrimaryNavBar from '@src/components/navigationBar/primary/HomePrimaryNavBar'
import { ROUTE } from '@src/constants/route'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const Container = styled(Box)`
  display: flex;
  width: 100%;
`

const StandAloneDownloadPage = styled(DownloadClientPage)`
  height: 100%;
`

const Home: NextPage<Props> = () => {
  const { isLoggedIn, account } = useAccountContext()

  const router = useRouter()

  useEffect(() => {
    if (isLoggedIn) {
      router.replace(ROUTE.BATTLE_DEMO_GAME)
    }
  }, [account.chainType, isLoggedIn, router])

  return (
    <Container>
      <HomePrimaryNavBar />
      <StandAloneDownloadPage homeMode />
    </Container>
  )
}

type Props = {}

export default Home
